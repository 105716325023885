import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import "./Custom.css";
import { setAttributes } from "../../utils";

import Row from "./Row/Row";

const Custom = ({ rows, advancedSettings, postType, modal }) => {
  const defaultClass = "section custom";
  let attributes = setAttributes(defaultClass, advancedSettings);

  return (
    <section
      id={attributes.id}
      aria-label={attributes.ariaLabel}
      className={attributes.className}
      data-settings={attributes.settings}
      style={attributes.style}
    >
      {advancedSettings.design.backgroundImage ? (
        <GatsbyImage
          image={
            advancedSettings.design.backgroundImage.imageFile.childImageSharp
              .gatsbyImageData
          }
          className={"section__background"}
          alt={advancedSettings.design.backgroundImage.altText}
          title={advancedSettings.design.backgroundImage.title}
          width={1920}
          height={1080}
          imgStyle={{
            objectPosition: advancedSettings.design.backgroundPosition,
          }}
          objectPosition={advancedSettings.design.backgroundPosition}
        />
      ) : null}
      {attributes.className.search("parallax-hills") !== -1 ? (
        <>
          <div className="parallax__layer gradient" />
          <img
            src="https://admin.freytagortho.com/app/uploads/2020/06/mountain-1.png"
            alt="Mountain Background"
            loading="lazy"
            className="parallax__layer mountain1"
            aria-hidden="true"
          />
          <img
            src="https://admin.freytagortho.com/app/uploads/2020/06/mountain-2.png"
            alt="Mountain Background"
            loading="lazy"
            className="parallax__layer mountain2"
            aria-hidden="true"
          />
          <img
            src="https://admin.freytagortho.com/app/uploads/2020/06/mountain-3.png"
            alt="Mountain Background"
            loading="lazy"
            className="parallax__layer mountain3"
            aria-hidden="true"
          />
        </>
      ) : null}
      {attributes.className.search("parallax-circles") !== -1 ? (
        <>
          <img
            className="parallax__layer solid-circles"
            src="https://admin.freytagortho.com/app/uploads/2020/06/solid-circles.png"
            alt="Circle Background Decoration"
            loading="lazy"
            aria-hidden="true"
          />
          <img
            className="parallax__layer stripe-circles"
            src="https://admin.freytagortho.com/app/uploads/2020/06/stripe-circles.png"
            alt="Striped Circle Background Decoration"
            loading="lazy"
            aria-hidden="true"
          />
        </>
      ) : null}
      {advancedSettings.design.backgroundOverlay ? (
        <div
          className="section__overlay"
          aria-hidden="true"
          style={attributes.style}
        ></div>
      ) : null}
      <div
        className={[
          "container container-fluid",
          attributes.containerClasses,
        ].join(" ")}
      >
        {rows.map((row, index) => {
          return (
            <Row key={index} row={row} postType={postType} modal={modal} />
          );
        })}
      </div>
    </section>
  );
};

export default Custom;
